<template>
  <button title="Back" class="w-10 h-10 flex items-center justify-center" :class="$style.back" @click="handleNavigate">
    <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.79585 15.6583L20.1994 29.5103L18.3962 31.033L0.189496 15.6583L18.3962 0.283586L20.1994 1.80628L3.79585 15.6583Z"
        fill="#12E2FF"
      />
      <g opacity="0.7" filter="url(#filter0_f_1290_38175)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.7151 15.6622L25.0408 26.9151L23.5759 28.1521L8.78539 15.6622L23.5759 3.17235L25.0408 4.40934L11.7151 15.6622Z"
          fill="#12E2FF"
        />
        <path
          d="M11.5347 15.8145L11.3544 15.6622L11.5347 15.51L24.6801 4.40934L23.5759 3.47689L9.14602 15.6622L23.5759 27.8476L24.6801 26.9151L11.5347 15.8145Z"
          stroke="#12E2FF"
          stroke-width="0.472018"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1290_38175"
          x="5.95329"
          y="0.340252"
          width="21.9196"
          height="30.644"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.41606" result="effect1_foregroundBlur_1290_38175" />
        </filter>
      </defs>
    </svg>
  </button>
</template>
<script setup lang="ts">
const router = useRouter()
const { history } = router.options

function handleNavigate() {
  if (history.state.back) {
    router.back()
  } else {
    router.push('/')
  }
}
</script>
<style lang="postcss" module>
.back {
  &:hover {
    svg {
      animation: movePath 0.6s ease forwards;
    }
  }
}

@keyframes movePath {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-12px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
